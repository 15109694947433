sv.i18n.portlet=sv.i18n.portlet || {};
sv.i18n.portlet.archive=sv.i18n.portlet.archive || {};
sv.i18n.portlet.archive.archive=sv.i18n.portlet.archive.archive || {};
sv.i18n.portlet.archive.archive.de={"creatingArticle": "Artikel wird erstellt..."};
sv.i18n.portlet.archive.archive.en={"creatingArticle": "Creating article..."};
sv.i18n.portlet.archive.archive.fi={"creatingArticle": "Luo artikkelia..."};
sv.i18n.portlet.archive.archive.no={"creatingArticle": "Oppretter artikkel ..."};
sv.i18n.portlet.archive.archive.sv={"creatingArticle": "Skapar artikel..."};
sv.i18n.portlet=sv.i18n.portlet || {};
sv.i18n.portlet.blog=sv.i18n.portlet.blog || {};
sv.i18n.portlet.blog.blog=sv.i18n.portlet.blog.blog || {};
sv.i18n.portlet.blog.blog.de={"creatingBlogEntry": "Blogbeitrag wird erstellt..."};
sv.i18n.portlet.blog.blog.en={"creatingBlogEntry": "Creating blog entry..."};
sv.i18n.portlet.blog.blog.fi={"creatingBlogEntry": "Luo blogiyhteyttä..."};
sv.i18n.portlet.blog.blog.no={"creatingBlogEntry": "Oppretter blogginnlegg ..."};
sv.i18n.portlet.blog.blog.sv={"creatingBlogEntry": "Skapar blogginlägg..."};
sv.i18n.portlet=sv.i18n.portlet || {};
sv.i18n.portlet.comments2=sv.i18n.portlet.comments2 || {};
sv.i18n.portlet.comments2.comments=sv.i18n.portlet.comments2.comments || {};
sv.i18n.portlet.comments2.comments.de={
   "moderatedBody": "Die Kommentarfunktion wird moderiert.\nIhr Kommentar wird erst sichtbar, wenn ein Moderator ihn genehmigt hat.",
   "removeReplyTitle": "Antwort entfernen",
   "noNameHeading": "Kein Name",
   "showLess": "Weniger anzeigen",
   "postFailedHeading": "FEHLER",
   "hiddenComment": "Verborgener Kommentar",
   "removeReplyBody": "Sind Sie sicher, dass Sie die Antwort löschen möchten?",
   "editReplyTitle": "Antwort bearbeiten",
   "removeCommentBody": "Sind Sie sicher, dass Sie den Kommentar löschen möchten?",
   "moderatedHeading": "Modereriertes Kommentarfeld",
   "doReply": "Antworten",
   "removeCommentTitle": "Kommentar entfernen",
   "reply": "Antworten",
   "commentToLongHeading": "Zu lang",
   "replies": "Antworten",
   "hiddenReply": "Verborgene Antwort",
   "commentToLongBody": "Der Kommentar, den Sie senden wollten, war zu lang.",
   "editCommentTitle": "Kommentar bearbeiten",
   "save": "Speichern",
   "showMore": "Mehr anzeigen",
   "postFailedBody": "Es ist ein Fehler aufgetreten, der Kommentar wurde nicht veröffentlicht.",
   "noNameBody": "Kein Name angegeben Name ist obligatorisch"
};
sv.i18n.portlet.comments2.comments.en={
   "moderatedBody": "The comments function is moderated.\nYour comment will become visible only after a moderator has approved it.",
   "removeReplyTitle": "Delete reply",
   "noNameHeading": "No name",
   "showLess": "Show less",
   "postFailedHeading": "ERROR",
   "hiddenComment": "Hidden comment",
   "removeReplyBody": "Are you sure you want to delete the reply?",
   "editReplyTitle": "Edit reply",
   "removeCommentBody": "Are you sure you want to delete the comment?",
   "moderatedHeading": "Moderated comments field",
   "doReply": "Reply",
   "removeCommentTitle": "Delete comment",
   "reply": "reply",
   "commentToLongHeading": "Too long",
   "replies": "replies",
   "hiddenReply": "Hidden reply",
   "showMore": "Show more",
   "commentToLongBody": "The comment you where trying to post was too long.",
   "editCommentTitle": "Edit comment",
   "save": "Save",
   "postFailedBody": "An error has occurred, the comment was not published.",
   "noNameBody": "No name given. Name is mandatory"
};
sv.i18n.portlet.comments2.comments.fi={
   "removeCommentBody": "Oletko varma, että haluat poistaa kommentin?",
   "commentToLongHeading": "Liian pitkä",
   "removeCommentTitle": "Poista kommentti",
   "showMore": "Näytä lisää",
   "noNameBody": "Nimeä ei annettu. Nimi on pakollinen",
   "showLess": "Näytä vähemmän",
   "commentToLongBody": "Yritit lähettää liian pitkän kommentin.",
   "moderatedHeading": "Moderoitu kommenttikenttä",
   "save": "Tallenna",
   "moderatedBody": "Kommentointitoiminto on moderoitu.\nKommenttisi tulee näkyviin, kun moderaattori on hyväksynyt sen.",
   "hiddenComment": "Poistunut kommentti",
   "noNameHeading": "Ei nimeä",
   "postFailedHeading": "VIRHE",
   "postFailedBody": "Tapahtui virhe, kommenttia ei julkaistu."
};
sv.i18n.portlet.comments2.comments.no={
   "removeCommentBody": "Er du sikker på at du vil fjerne kommentaren?",
   "commentToLongHeading": "For lang",
   "removeCommentTitle": "Fjern kommentar",
   "showMore": "Vis mer",
   "noNameBody": "Intet navn angitt. Navn er obligatorisk",
   "Din": "kommentar blir synlig først når en moderator har godkjent den.",
   "showLess": "Vis mindre",
   "moderatedHeading": "Moderert kommentarfelt",
   "commentToLongBody": "Kommentaren du forsøkte å sende, var for lang.",
   "save": "Lagre",
   "moderatedBody": "Kommentarfunksjonen er moderert.",
   "editCommentTitle": "Rediger kommentar",
   "hiddenComment": "Skjult kommentar",
   "noNameHeading": "Intet navn",
   "postFailedHeading": "FEIL",
   "postFailedBody": "Det oppsto en feil, kommentaren ble ikke publisert."
};
sv.i18n.portlet.comments2.comments.sv={
   "moderatedBody": "Kommentarsfunktionen är modererad.\nDin kommentar blir synlig först när en moderator godkänt den.",
   "removeReplyTitle": "Ta bort svar",
   "noNameHeading": "Inget namn",
   "showLess": "Visa mindre",
   "postFailedHeading": "FEL",
   "hiddenComment": "Dold kommentar",
   "removeReplyBody": "Är du säker på att du vill ta bort svaret?",
   "editReplyTitle": "Redigera svar",
   "removeCommentBody": "Är du säker på att du vill ta bort kommentaren?",
   "moderatedHeading": "Modererat kommentarsfält",
   "doReply": "Svara",
   "removeCommentTitle": "Ta bort kommentar",
   "reply": "svar",
   "commentToLongHeading": "För lång",
   "replies": "svar",
   "hiddenReply": "Dolt svar",
   "showMore": "Visa mer",
   "commentToLongBody": "Kommentaren du försökte skicka var för lång.",
   "editCommentTitle": "Redigera kommentar",
   "save": "Spara",
   "postFailedBody": "Ett fel har uppstått, kommentaren publicerades inte.",
   "noNameBody": "Inget namn angivet. Namn är obligatoriskt"
};
sv.i18n.common=sv.i18n.common || {};
sv.i18n.common.de={
   "networkErrorText": "Die Information konnte nicht aktualisiert werden. Es konnte kein Kontakt zum Server hergestellt werden.",
   "networkErrorTitle": "Fehler bei der Serverkommunikation",
   "decimal": ",",
   "cancel": "Abbrechen",
   "mins": "Min",
   "error-fileExists": "{0} ist bereits vorhanden.",
   "ok": "OK",
   "error-fileRenameHeading": "Der Name der Datei konnte nicht geändert werden",
   "add": "Hinzufügen",
   "close": "Beenden",
   "new": "Neu",
   "error": "Fehler",
   "error-fileExistsHeading": "Die Datei konnte nicht hinzugefügt werden",
   "help": "Hilfe",
   "save": "Speichern",
   "hrs": "Std.",
   "error-unknown": "Unbekannter Fehler.",
   "hr": "Std.",
   "remove": "Löschen"
};
sv.i18n.common.en={
   "networkErrorText": "Information could not be updated. Could not contact server.",
   "networkErrorTitle": "Communication with server failed",
   "decimal": ".",
   "cancel": "Cancel",
   "mins": "mins",
   "error-fileExists": "{0} already exists.",
   "ok": "OK",
   "error-fileRenameHeading": "Could not rename file",
   "add": "Add",
   "close": "Close",
   "new": "New",
   "error": "Error",
   "error-fileExistsHeading": "Could not add file",
   "help": "Help",
   "save": "Save",
   "hrs": "hrs",
   "error-unknown": "Unknown error.",
   "remove": "Delete",
   "hr": "hr"
};
sv.i18n.common.fi={
   "networkErrorText": "Aineistoa ei voitu päivittää. Palvelimeen ei saada yhteyttä.",
   "networkErrorTitle": "Ongelma palvelinyhteydessä.",
   "decimal": ",",
   "cancel": "Peruuta",
   "mins": "min",
   "error-fileExists": "{0} tiedosto on jo olemassa.",
   "ok": "OK",
   "error-fileRenameHeading": "Tiedoston nimen vaihto ei onnistunut",
   "add": "Luo tili",
   "close": "Sulje",
   "new": "Uusi",
   "error": "Virhe",
   "error-fileExistsHeading": "Tiedoston lisääminen ei onnistunut",
   "help": "Ohje",
   "save": "Tallenna",
   "hrs": "tuntia",
   "error-unknown": "Tuntematon virhe.",
   "remove": "Poista",
   "hr": "tunti"
};
sv.i18n.common.fr={"cancel": "Annuler"};
sv.i18n.common.no={
   "networkErrorText": "Informasjonen kunne ikke oppdateres. Kunne ikke kontakte serveren.",
   "networkErrorTitle": "Feil ved serverkommunikasjon",
   "decimal": ",",
   "cancel": "Avbryt",
   "mins": "min",
   "error-fileExists": "{0} finnes allerede.",
   "ok": "OK",
   "error-fileRenameHeading": "Det var ikke mulig å bytte navn på filen",
   "add": "Legg til",
   "close": "Lukk",
   "new": "Ny",
   "error": "Feil",
   "error-fileExistsHeading": "Det var ikke mulig å legge til filen",
   "help": "Hjelp",
   "save": "Lagre",
   "hrs": "time",
   "error-unknown": "Ukjent feil.",
   "remove": "Fjern",
   "hr": "time"
};
sv.i18n.common.pt={"cancel": "Cancelar"};
sv.i18n.common.sv={
   "networkErrorText": "Informationen kunde inte uppdateras. Kunde inte kontakta servern.",
   "networkErrorTitle": "Fel vid serverkommunikation",
   "decimal": ",",
   "cancel": "Avbryt",
   "mins": "min",
   "error-fileExists": "{0} finns redan.",
   "ok": "OK",
   "error-fileRenameHeading": "Det gick inte att byta namn på filen",
   "add": "Lägg till",
   "close": "Stäng",
   "new": "Ny",
   "error": "Fel",
   "error-fileExistsHeading": "Det gick inte att lägga till filen",
   "help": "Hjälp",
   "save": "Spara",
   "hrs": "tim",
   "error-unknown": "Okänt fel.",
   "remove": "Ta bort",
   "hr": "tim"
};
sv.i18n.util=sv.i18n.util || {};
sv.i18n.util.dialogutil=sv.i18n.util.dialogutil || {};
sv.i18n.util.dialogutil.de={
   "ok": "OK",
   "cancel": "Abbrechen"
};
sv.i18n.util.dialogutil.en={
   "ok": "OK",
   "cancel": "Cancel"
};
sv.i18n.util.dialogutil.fi={
   "ok": "OK",
   "cancel": "Peruuta"
};
sv.i18n.util.dialogutil.no={
   "ok": "OK",
   "cancel": "Avbryt"
};
sv.i18n.util.dialogutil.sv={
   "ok": "OK",
   "cancel": "Avbryt"
};
sv.i18n.util=sv.i18n.util || {};
sv.i18n.util.errorutil=sv.i18n.util.errorutil || {};
sv.i18n.util.errorutil.de={
   "errorMessage": "Es ist ein Problem aufgetreten, das dazu geführt hat, dass die Aufgabe nicht wie vorgesehen ausgeführt werden konnte ({0})",
   "connectionErrorMessageTitle": "Verbindungsproblem",
   "errorMessageTitle": "Es ist ein Fehler aufgetreten",
   "connectionErrorMessage": "Es konnte keine Verbindung zum Server hergestellt werden. Bitte versuchen Sie es später erneut"
};
sv.i18n.util.errorutil.en={
   "errorMessage": "A problem occurred that prevented the task from being performed ({0})",
   "connectionErrorMessageTitle": "Connection error",
   "errorMessageTitle": "An error occurred",
   "connectionErrorMessage": "Could not connect to the server, please try again later"
};
sv.i18n.util.errorutil.fi={
   "errorMessage": "Toimintoa ei voitu virheen takia suorittaa pyydetyllä tavalla ({0})",
   "connectionErrorMessageTitle": "Yhteysongelma",
   "errorMessageTitle": "Tapahtui virhe",
   "connectionErrorMessage": "Palvelimeen ei saatu yhteyttä, kokeile myöhemmin uudelleen"
};
sv.i18n.util.errorutil.no={
   "errorMessage": "Det oppsto et problem som gjorde at opplysningen ikke kunne utføres slik det var tenkt ({0})",
   "connectionErrorMessageTitle": "Tilkoblingsproblem",
   "errorMessageTitle": "Det oppsto en feil",
   "connectionErrorMessage": "Kunne ikke koble til serveren, prøv igjen senere"
};
sv.i18n.util.errorutil.sv={
   "errorMessage": "Ett problem uppstod som gjorde att uppgiften inte kunde utföras som det var tänkt ({0})",
   "connectionErrorMessageTitle": "Anslutningsproblem",
   "errorMessageTitle": "Ett fel inträffade",
   "connectionErrorMessage": "Kunde inte ansluta till servern, var god försök igen senare"
};
sv.i18n.portlet=sv.i18n.portlet || {};
sv.i18n.portlet.search=sv.i18n.portlet.search || {};
sv.i18n.portlet.search.searchform2=sv.i18n.portlet.search.searchform2 || {};
sv.i18n.portlet.search.searchform2.searchform2=sv.i18n.portlet.search.searchform2.searchform2 || {};
sv.i18n.portlet.search.searchform2.searchform2.de={
   "ugc": "Benutzergenerierte Inhalte",
   "results": "Suchergebnis",
   "content": "Inhalt",
   "searchFailed": "Die Suche mit dem Fehlercode {0} ist fehlgeschlagen.",
   "users": "Personen"
};
sv.i18n.portlet.search.searchform2.searchform2.en={
   "ugc": "User generated content",
   "results": "search results",
   "content": "Content",
   "searchFailed": "Search failed with error code {0}.",
   "users": "Users"
};
sv.i18n.portlet.search.searchform2.searchform2.fi={"searchFailed": "Haku epäonnistui, virhekoodi {0}."};
sv.i18n.portlet.search.searchform2.searchform2.no={"searchFailed": "Søket mislyktes med feilkode {0}."};
sv.i18n.portlet.search.searchform2.searchform2.sv={
   "ugc": "Användargenererat innehåll",
   "results": "sökresultat",
   "content": "Innehåll",
   "searchFailed": "Sökningen misslyckades med felkod {0}.",
   "users": "Personer"
};
sv.i18n.portlet=sv.i18n.portlet || {};
sv.i18n.portlet.sharepoint=sv.i18n.portlet.sharepoint || {};
sv.i18n.portlet.sharepoint.sharepointfile=sv.i18n.portlet.sharepoint.sharepointfile || {};
sv.i18n.portlet.sharepoint.sharepointfile.de={
   "loginButton": "Anmeldung",
   "connectToSharePoint": "An SharePoint anschließen"
};
sv.i18n.portlet.sharepoint.sharepointfile.en={
   "loginButton": "Log in",
   "connectToSharePoint": "Connect to SharePoint"
};
sv.i18n.portlet.sharepoint.sharepointfile.fi={};
sv.i18n.portlet.sharepoint.sharepointfile.no={
   "loginButton": "Logg inn",
   "connectToSharePoint": "Koble til SharePoint"
};
sv.i18n.portlet.sharepoint.sharepointfile.sv={
   "loginButton": "Logga in",
   "connectToSharePoint": "Anslut till SharePoint"
};
sv.i18n.util=sv.i18n.util || {};
sv.i18n.util.viewutil=sv.i18n.util.viewutil || {};
sv.i18n.util.viewutil.de={"selectView": "Ansicht auswählen"};
sv.i18n.util.viewutil.en={"selectView": "Select view"};
sv.i18n.util.viewutil.fi={"selectView": "Valitse näkymä"};
sv.i18n.util.viewutil.no={"selectView": "Velg visning"};
sv.i18n.util.viewutil.sv={"selectView": "Välj vy"};
