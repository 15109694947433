/**
 * Copyright (C) Sitevision AB 2002-2023, all rights reserved
 *
 */
import './imageSlideShow';
import sv from '@sv/core';

document.querySelectorAll('.sv-imageslideshow-portlet').forEach((item) => {
  const id = item.getAttribute('id');
  sv.createAndLoadSlideshowForId('#' + id);
});
